<template>
  <div class="one-page-parent noto-sans content-main-space" style="background-color: white; vertical-align: middle;">
    <div style="margin: 40px auto 40px;">
      <div>
        <div style="padding-bottom: 2.125rem; text-align: center;">
          <img src="../../../public/images/HowCost-title-logo.png" alt="..." width="250px">
        </div>

        <div class="m-card" style="border: solid 1px rgb(225, 225, 225); padding: 3rem;">

          <form @submit="onSubmit">
            <div class="pb-1" style="border-bottom: solid 1px #ccc;">
              <input class="login-input" v-model="userId" type="text" autocomplete="off" placeholder="아이디"/>
            </div>
            <div class="pt-3 pb-1" style="border-bottom: solid 1px #ccc;">
              <input class="login-input" v-model="userPwd" type="password" autocomplete="off" placeholder="비밀번호"/>
            </div>
            <div class="pt-5 pb-2">
              <button class="btn-howcost" type="submit">로그인</button>
            </div>
          </form>

          <div class="both-side-cont">
            <div style="text-align: left; display: inline-block;">
              <a href="#/member-find" class="a-custom">비밀번호 찾기</a>
            </div>
            <div style="text-align: right;">
              <a href="#/service/register" class="a-custom">회원가입</a>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-if="failedCount >= 1" style="margin: 0 auto 0;">
      <b-alert show variant="info">
        <h4 class="alert-heading mb-2" style="font-weight: bold;">로그인이 안되시는 경우</h4>
        <hr class="my-2">
        <p class="mb-0">혹시 커뮤니티 사이트에서 가입하셨나요?</p>
        <p class="mb-0">커뮤니티로 처음 가입하신 분께서는 xcost 프로그램에서 최초 1회 이상 로그인이 진행 되어야 합니다.</p>
        <a class="mb-0" :href="$xcostDownload">Xcost다운로드</a>

        <div class="mt-2">
          <span class="mb-0">로그인이 지속적으로 어려우시다면 </span>
          <a class="mb-0" href="http://xcost.me/bbs/qalist.php">고객센터</a>
          <span class="mb-0">로 문의 부탁드립니다.</span>
        </div>
      </b-alert>
    </div>

  </div>
</template>

<script>
import {alertError, alertWarn, apiCall} from '../../common/utils';
import store from "@/store";

  export default {
  name: 'Login',
  data(){
    return {
      loginMessage: 'LOGIN 하세요',
      userId: null,
      userPwd: null,
      isProcessing: false,
      failedCount: 0,
      userInfo: null,
    }
  },
  async created(){
    // console.log("this.$store.dispatch -----------------> ", this.$store.dispatch);

    if( this.$store.state.userStore.isAuth ){
      this.redirect();
    }

    const r = await apiCall('GET', `/user/auth/get-token`);

    if( r.result ){
      //console.log( "######## Login.created() ------------ localStorage.userInfo: ", localStorage.userInfo );
      try{
        const rs = await this.$store.dispatch('userStore/setLoginState', r.result);
        console.log("Login rs ==============> ", rs)
        if(rs) this.redirect();
      }catch(err){
        console.error( "[CREATED] -------------- err: ", err.message );
      }
    }
  },
  beforeMount(){},
  mounted() {},

  methods: {
    async onSubmit(evt){
      evt.preventDefault();
      console.log("------------------> Login.onSubmit()..............");

      try{
        this.isProcessing = true;
        const rs = await this.$store.dispatch('userStore/LOGIN', {userId:this.userId, userPwd:this.userPwd});
        console.log( "---- Login.onSubmit ...................rs: ", rs);

        const r = await apiCall( 'GET', `/user/auth/me` );
        // console.log(" userInfoVerify ", r);
        const user = r.result;

        if(!rs){
          // 로그인 실패
          await alertWarn(this.$bvModal, "아이디 또는 비밀번호가 일치하지 않습니다.", "로그인 실패");
          this.failedCount++;
        } else if (!user.email || !user.hpNo || !user.name) {
          // 로그인 성공이지만 사용자 정보가 없을 경우 :: 사용자 정보 업데이트 요청
          alert(`${user.userId}님, 계정 정보를 업데이트 해주셔야 서비스 이용이 가능합니다.`);
          await this.userInfoVerify(user);
        } else {
          // 로그인 성공
          this.redirect();
        }
      }catch(err){
        console.error( err );
        await alertError( this.$bvModal, err.message, "9999" );
      }finally{
        this.isProcessing = false;
      }
      // console.log("#########################===============> ", res);
    },

    redirect() {
      // console.log("redirect() ===============> H_isAuth: ", this.$store.state.userStore.isAuth);

      const redirectPath = this.$route.query.redirect || '/'; // 기본값은 홈('/') 경로
      this.$router.push(redirectPath);
    },

    async userInfoVerify(user) {
      try {
        // 기존 회원정보 수정 폼 활용
        const params = {
          id: user.userId,
          no: user.userNo,
          hostName: window.location.hostname
        }

        const r = await apiCall('post', `/api/member/update-request`, params);

        // 강제 로그아웃
        this.$store.dispatch('userStore/LOGOUT');

        window.location = r.result.link;
      } catch (e) {
        console.error(e);
      }
    }
  }
}
</script>
